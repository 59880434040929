<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-2.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="710"
                class="auth-3d-group"
                :src="
                  require(`@/assets/images/3d-characters/illustration-register-v2-${
                    $vuetify.theme.dark ? 'dark' : 'light'
                  }.png`)
                "
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary my-2">{{ $t('adventureStartsHere') }} 🚀</p>
                  <p v-t="'fromNowOnYourWork'" class="mb-2" />
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="registerForm" @submit.prevent="handleFormSubmit">
                    <v-text-field
                      v-model="firstName"
                      outlined
                      :label="$t('firstName', { postfix: '' })"
                      :rules="[validators.required]"
                      :placeholder="$t('firstName', { postfix: '' })"
                      hide-details="auto"
                      class="mb-6"
                      maxlength="150"
                      autocomplete="given-name"
                    ></v-text-field>
                    <v-text-field
                      v-model="lastName"
                      outlined
                      :label="$t('lastName', { postfix: '' })"
                      :rules="[validators.required]"
                      :placeholder="$t('lastName', { postfix: '' })"
                      hide-details="auto"
                      class="mb-6"
                      maxlength="150"
                      autocomplete="family-name"
                    ></v-text-field>

                    <v-text-field
                      v-model="email"
                      outlined
                      :error-messages="errorMessages.email"
                      :rules="[validators.required, validators.emailValidator]"
                      :label="$t('email', { postfix: '' })"
                      :placeholder="$t('email', { postfix: '' })"
                      hide-details="auto"
                      class="mb-6"
                      maxlength="254"
                      autocomplete="email"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :label="$t('password')"
                      :error-messages="errorMessages.password"
                      :placeholder="$t('password')"
                      :rules="[validators.required, validators.passwordValidator]"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      maxlength="128"
                      autocomplete="new-password"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>
                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isConfirmPasswordVisible ? 'text' : 'password'"
                      :label="$t('confirmPassword')"
                      :error-messages="errorMessages.password"
                      :placeholder="$t('confirmPassword')"
                      :rules="[validators.required, validators.confirmPasswordValidator(confirmPassword, password)]"
                      :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      maxlength="128"
                      autocomplete="new-password"
                      @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    ></v-text-field>

                    <v-checkbox
                      v-model="termsAndConditions"
                      class="mt-0"
                      hide-details="auto"
                      :rules="[validators.requiredCheckbox]"
                    >
                      <template #label>
                        <div class="d-flex align-center flex-wrap">
                          <span class="me-2">{{ $t('iAgreeTo') }}</span
                          ><a href="https://lightapply.com/pl/regulamin/" target="_blank" rel="nofollow" @click.stop>{{
                            $t('termsAndConditions')
                          }}</a>
                        </div>
                      </template>
                    </v-checkbox>

                    <v-checkbox
                      v-model="privacyPolicy"
                      class="mt-0"
                      hide-details="auto"
                      :rules="[validators.requiredCheckbox]"
                    >
                      <template #label>
                        <div class="d-flex align-center flex-wrap">
                          <span class="me-2">{{ $t('iAgreeTo') }}</span
                          ><a
                            href="https://lightapply.com/pl/polityka-prywatnosci/"
                            target="_blank"
                            rel="nofollow"
                            @click.stop
                            >{{ $t('privacyPolicy') }}</a
                          >
                        </div>
                      </template>
                    </v-checkbox>

                    <v-btn block color="primary" type="submit" class="mt-6" :loading="loading">
                      {{ $t('signUp') }}</v-btn
                    >
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p v-t="'alreadyHaveAnAccount'" class="mb-0 me-2" />
                  <router-link :to="{ name: 'auth-login' }"> {{ $t('signInInstead') }} </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import {
  required,
  emailValidator,
  passwordValidator,
  confirmPasswordValidator,
  requiredCheckbox,
} from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    // Template Refs
    const registerForm = ref(null)

    const { router } = useRouter()

    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const errorMessages = ref([])
    const termsAndConditions = ref(false)
    const privacyPolicy = ref(false)
    const loading = ref(false)

    const { createRecruiterProfile } = useActions('recruiter', ['createRecruiterProfile'])

    const handleFormSubmit = async () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      loading.value = true
      const accountCreated = await createRecruiterProfile({
        user: {
          firstName: firstName.value,
          lastName: lastName.value,
          password: password.value,
          email: email.value,
        },
        termsAndConditionsAccepted: termsAndConditions.value,
        privacyPolicyAccepted: privacyPolicy.value,
      })
      loading.value = false
      if (accountCreated) router.push('/')
    }

    return {
      isPasswordVisible,
      isConfirmPasswordVisible,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      termsAndConditions,
      privacyPolicy,
      loading,
      errorMessages,
      handleFormSubmit,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        confirmPasswordValidator,
        requiredCheckbox,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      registerForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
